.Available-container {
    width: 100%;
    margin-bottom: 0;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    background-color: #ee616d;
    position: relative;
    overflow: hidden; /* Ensure content does not overflow */
  }
  
  .Content-container {
    color: white;
    padding: 1.5rem;
    position: relative; /* Ensure position is relative */
    overflow: hidden;
    padding-top: 70px; /* Add padding to make space for the wave */
  }
  
  