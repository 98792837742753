/* src/pages/Career.css */

.career-page {
  padding: 20px;
  text-align: center;
}

.career-page h1 {
  color: #000;
  font-size: 46px;
  font-style: normal;
  line-height: normal;
  font-family: "Lexend Deca", sans-serif;
}

.career-page h2 {
  color: #000;
  font-size: 35px;
  font-family: "Lexend Deca", sans-serif;
}

.explore {
  text-align: center; /* Center align the button */
  margin-top: 10px; /* Adjust spacing from above content */
}

.explore-job-button {
  border-radius: 33px;
  background: #fb2b66;
  color: #ffffff !important;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  border: none;
  box-shadow: none;
  text-decoration: none;
  font-family: "Lexend Deca", sans-serif;
}

.page-cont {
  padding: 20px;
  text-align: center;
}

.page-cont p {
  color: #000;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 40px;
  width: 1200px;
  align-items: center;
  margin: auto;
  font-family: "Lexend Deca", sans-serif;
}

.container1 {
  margin-left: auto; /* Center horizontally */
  margin-right: auto;
  margin-top: 40px;
  padding: 40px;
  margin-bottom: 35px !important;
  background-color: #f4f4f4 !important;
  border-radius: 15px !important;
  width: 70%;
  align-items: center;
}

.container2 p {
  font-size: 18px;
  font-style: normal;
  margin-top: 0;
  padding-top: 0;
  font-weight: 400;
  line-height: 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Lexend Deca", sans-serif;
}

.container3 {
  margin-left: auto; /* Center horizontally */
  margin-right: auto;
  margin-top: 40px;
  padding: 40px;
  margin-bottom: 35px !important;
  background-color: #f4f4f4 !important;
  border-radius: 15px !important;
  width: auto;
  align-items: center;
}

.container3 h2 {
  font-size: 35px;
  padding-left: 300px;
  align-items: center;
  font-family: "Lexend Deca", sans-serif;
}

.listing {
  width: 50%;
  margin-left: 600px;
  margin-right: 600px;
  margin-top: 0%;
}
.listing li {
  font-weight: 300;
  font-size: 18px;
  font-family: "Lexend Deca", sans-serif;
}
