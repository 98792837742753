body{
  font-family: "Lexend Deca", sans-serif;
}
.clientBox .clientImg
{
  border-radius: 50%;
}
.montserrat-top-text {
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.videoContainer {
  background: #f5f5f5;
  width: 100%;
  padding: 50px 0;
}
.videoContainer .contentAi {
  font-size: 20px;
  padding-left: 30px;
  line-height: 40px;
  position: relative;
}
.videoContainer .contentAi::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  top: 0px;
  left: 0px;
  background: #1eafb3;
}

.Reviews {
  margin-top: 150px;
  margin-bottom: 100px;
}
.comments {
  margin-top: 100px;
  margin-bottom: 100px;
}

.audio-wave-gif {
  width: 55%;
}