#chat1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#chat1 .message-row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

#chat1 .message-icon {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#chat1 .message-content {
  padding: 16px;
  border-radius: 15px;
}

#chat1 .message-content.doctor {
  background-color: #f0f0f0; /* very light grey */
  margin-left: 16px;
}

#chat1 .message-content.patient {
  background-color: #ffffff; /* white */
  margin-right: 16px;
  border: 1px solid #eee;
}

#chat1 .form-outline ~ .form-notch div {
  pointer-events: none;
  border: 1px solid;
  border-color: #eee;
  box-sizing: border-box;
  background: transparent;
}

#chat1 .form-outline ~ .form-notch .form-notch-leading {
  left: 0;
  top: 0;
  height: 100%;
  border-right: none;
  border-radius: 0.65rem 0 0 0.65rem;
}

#chat1 .form-outline ~ .form-notch .form-notch-middle {
  flex: 0 0 auto;
  max-width: calc(100% - 1rem);
  height: 100%;
  border-right: none;
  border-left: none;
}

#chat1 .form-outline ~ .form-notch .form-notch-trailing {
  flex-grow: 1;
  height: 100%;
  border-left: none;
  border-radius: 0 0.65rem 0.65rem 0;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-leading {
  border-top: 0.125rem solid #FB2B66;
  border-bottom: 0.125rem solid #FB2B66;
  border-left: 0.125rem solid #FB2B66;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-leading,
#chat1 .form-outline.active ~ .form-notch .form-notch-leading {
  border-right: none;
  transition: all 0.2s linear;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-middle {
  border-bottom: 0.125rem solid;
  border-color: #FB2B66;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-middle,
#chat1 .form-outline.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-right: none;
  border-left: none;
  transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-top: 0.125rem solid #FB2B66;
  border-bottom: 0.125rem solid #FB2B66;
  border-right: 0.125rem solid #FB2B66;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-trailing,
#chat1 .form-outline .form-control.active ~ .form-notch .form-notch-trailing {
  border-left: none;
  transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-label {
  color: #FB2B66;
}

#chat1 .form-outline .form-control ~ .form-label {
  color: #bfbfbf;
}
