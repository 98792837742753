.about-container {
  padding: 40px;
  max-width: fit-content;
  margin: 0 auto;
  font-family: "Lexend Deca", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  border-radius: 10px;
 
}

.about-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #000000;
  font-weight: 500;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}



.about-container p {
  margin-bottom: 20px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 24px;
  font-family: "Lexend Deca", sans-serif;
}

.about-container p:last-child {
  margin-bottom: 0;
  font-family: "Lexend Deca", sans-serif;
}


.about-2container{
  margin-left: 0 auto;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-left: 300px;
  position: relative;
  left: -358.867px;
  box-sizing: border-box;
  width: 100%;
  
  font-family: "Lexend Deca", sans-serif;
}


.about-left, .about-right {
  flex: 1;
  padding: 0 20px;
  margin-top: 50px;
  margin-left: 70px;

}

.about-left h2, .about-right h2 {
  font-size: 1.8em;
  margin-top: 0;
  margin-bottom: 20px;
  color: #000;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.about-left p, .about-right p {
  margin-bottom: 20px;
  padding: 0 25px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  font-family: "Lexend Deca", sans-serif;
}

.work-button {
  border-radius: 33px;
  background: #FB2B66;
  color: #ffffff !important;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  border: none;
  box-shadow: none;
  text-decoration: none;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  font-family: "Lexend Deca", sans-serif;
}

.want-towork {
  align-items: center;
  text-align:center;
  padding: 40px;
}

.want-towork h2 {
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
  font-size: 40;
  text-align: center;
}

.want-towork p{
  font-size: 24px; 
  font-weight : 400;
      text-align: center;
      font-family: "Lexend Deca", sans-serif;
}

.work-button {
  align-items: center;
  font-family: "Lexend Deca", sans-serif;
}