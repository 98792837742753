.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-add {
  background: white;
  padding: 20px;
  border-radius: 5px;
  /* width: 500px; */
  width: 90%;
  max-width: 100%;
  border-radius: 1rem;
}

.close-button {
  position: relative;
  z-index: 3;
  float: right;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar-tab {
  display: block;
  padding: 10px;
  margin-bottom: 5px;
  background: none;
  border: none;
  border-radius: 0.2rem;
  text-align: left;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-tab:hover {
  background-color: #e0e0e0;
}

.sidebar-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 76vh;
}

.modal-sidebar {
  flex: 0 0 150px;
  background: #f4f4f4;
  border-radius: 0.5rem;
  padding: 10px;
  order: 1;
}

.modal-content {
  flex: 1;
  padding: 20px;
  background: white;
  overflow-y: auto;
  order: 2;
}

.sidebar-tab.active {
  background-color: #007bff;
  color: white;
}

@media screen and (max-width: 700px) {
  .sidebar-container {
    flex-direction: column;
  }

  .modal-sidebar {
    display: flex;
    flex: 0;
    width: 100%;
    order: 0;
  }

  .modal-content {
    order: 1;
  }
}

.card-dim {
  width: 370px;
  height: 200px;
  border-radius: 1rem;
  margin: 1rem 1rem;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
}

.adding-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px dashed rgb(151, 151, 151);
}

.adding-card > svg {
  font-size: 2rem;
  color: #3f3838;
  margin-right: 0.5rem;
}

.adding-card > h6 {
  font-size: 0.9rem;
  color: #3f3838;
}

/* -----------------Image-uploader------------------- */

.file-uploader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.file-upload-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 70%;
  border-radius: 1rem;
  padding: 7rem 0rem;
  margin: 3rem 0rem;
  background-color: white;
  border: 2px dashed gray;
}

.droping {
  background-color: rgba(122, 122, 122, 0.11) !important;
}

.file-upload-container p {
  font-size: 0.8rem;
  font-weight: 600;
}

.upload-icon {
  margin-bottom: 1rem;
}

.file-preview-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10vh 0rem;
}

.file-preview {
  position: relative;
  display: flex;
  padding: 0.7rem 1.5rem;
  /* margin: 0.3rem auto; */
  margin: 15px;
  width: 22vw;
  background: #dcdcdc85;
  box-shadow: 0px 0px 3px 0px rgb(66 66 66 / 45%);
}

.file-preview img {
  width: 32px;
}

.remove-file {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  border-radius: 10%;
  padding: 0.1rem 0.3rem;
  font-size: 0.8rem;
  cursor: pointer;
  background: transparent;
}

.file-name {
  color: #000;
  text-align: center;
}

.file-submit {
  position: absolute;
  bottom: 0;
  right: 0;
}

.file-loading-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-submit > button {
  padding: 0.5rem 2.3rem;
  border: 0px;
  border-radius: 0.3rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: #fff;
  background-color: #1677ff;
}

.file-submit > button:hover {
  background-color: #4096ff;
}
