.contact-us {
  padding: 20px;
  position: relative;
  margin: 80px;
  box-sizing: border-box;
}

.contact-us h1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #000;
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.contact-us p {
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 24px;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  position: relative;

  box-sizing: border-box;
}

.contact-section {
  margin: 20px;
  width: 33.33333333%;
}

.contact-section h2 {
  font-size: 1.5em;
  text-align: center;
  font-size: 26px;
  font-family: "Lexend Deca", sans-serif;
}

.contact-section p {
  font-size: 1.2em;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 24px;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}
