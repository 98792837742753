.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 100px; /* Add space from the top */
  width: 60%;
  margin: 20px auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: "Lexend Deca", sans-serif;
}

.form-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.form-subtitle {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.form-row {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 1rem;
}

.form-control {
  margin-bottom: 1rem;
  width: 100%;
}

.form-checkbox-label {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form-checkbox {
  padding-right: 10px;
}

.form-privacy {
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;
}

.submit-button {
  margin-top: 1rem;
  background-color: #f50057;
  color: #fff;
  font-family: "Lexend Deca", sans-serif;
  font-size: 24px;
}

.submit-button:hover {
  background-color: #c51162;
}
