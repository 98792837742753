/* Importing Google font -Open+Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}


.Price-container {
    width: 460px;
    padding: 40px;
    background: #ffffff;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    position: relative;
    font-family: 'Open Sans', sans-serif;
}

.container .title {
    font-size: 2rem;
    color: #333;
}

.container .price {
    color: var(--logo-color);
    font-weight: 700;
    font-size: 55px;
    margin: 15px 0;
}

.container .description {
    color: #3b3b3b;
    font-size: 1.1rem;
    margin: 20px 0 20px;
}

.container .offer {
    display: block;
    color: var(--black-Color60);
    font-size: 1rem;
    margin-top: 25px;
}

.subscribe-button {
    display: inline-block;
    padding: 15px 0;
    background: var(--logo-color);
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-size: 1.2rem;
    margin-top: 40px;
    width: 100%;
    font-weight: 500;
    transition: 0.2s ease;
}

.subscribe-button:hover {
    background: var(--black-Color);
}

.ribbon-wrap {
    width: 150px;
    height: 150px;
    position: absolute;
    top: -10px;
    left: -10px;
    pointer-events: none;
}

.ribbon {
    width: 230px;
    font-size: 0.918rem;
    text-align: center;
    padding: 8px 0;
    background: var(--logo-color);
    color: #fff;
    position: absolute;
    transform: rotate(-45deg);
    right: -17px;
    top: 29%;
}